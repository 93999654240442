'use client'

import useSWR from 'swr'

import { getGamesPlaytest } from '../api'
import { GAMES_FRAMES_PLAYTEST_REVALIDATE_KEY } from '../config'

export const useGamesFramesPlaytest = () => {
  const {
    data: { data: games },
  } = useSWR([GAMES_FRAMES_PLAYTEST_REVALIDATE_KEY], () => getGamesPlaytest(), {
    suspense: true,
  })

  return { games }
}
